import {
  bufferToBase64,
  base64ToBuffer,
} from './Util.js';

const E0_SALT_SIZE = 16;
const E0_IV_SIZE = 16;
const E0_KEY_SIZE = 256;
const E0_COST = 2 * 1000 * 1000;

export async function kmEncrypt(passphrase, data) {
  return await kmEncrypt0(passphrase, data);
}

export async function kmDecrypt(passphrase, data) {
  const version = data.split('$')[0];
  if ('0' === version) {
    return await kmDecrypt0(passphrase, data);
  }
  throw new Error('Invalid ciphertext version ' + version);
}

async function kmDeriveKey0(passphrase, salt, cost, key_size) {
  const raw = await window.crypto.subtle.importKey(
    'raw',
    passphrase,
    { name: 'PBKDF2' },
    false,
    ['deriveKey'],
  );
  return await window.crypto.subtle.deriveKey(
    {
      name: 'PBKDF2',
      salt: salt,
      iterations: cost,
      hash: 'SHA-256',
    },
    raw,
    {
      name: 'AES-GCM',
      length: key_size,
    },
    false,
    ['encrypt', 'decrypt'],
  );
}

async function kmEncrypt0(passphrase, data) {
  const salt = new Uint8Array(E0_SALT_SIZE);
  await window.crypto.getRandomValues(salt);
  const iv = new Uint8Array(E0_IV_SIZE);
  await window.crypto.getRandomValues(iv);
  const cost = E0_COST;
  const key_size = E0_KEY_SIZE;
  const key = await kmDeriveKey0(passphrase, salt, cost, key_size);
  const ciphertext = new Uint8Array(await window.crypto.subtle.encrypt(
    {
      name: 'AES-GCM',
      iv: iv,
    },
    key,
    data,
  ));
  const result = [
    '0',
    bufferToBase64(salt),
    bufferToBase64(iv),
    '' + cost,
    '' + key_size,
    bufferToBase64(ciphertext),
  ];
  return result.join('$');
}

async function kmDecrypt0(passphrase, data) {
  let [ salt, iv, cost, key_size, ciphertext ] = data.split('$').slice(1); // ignore version
  salt = base64ToBuffer(salt);
  iv = base64ToBuffer(iv);
  cost = parseInt(cost);
  key_size = parseInt(key_size);
  ciphertext = base64ToBuffer(ciphertext);
  const key = await kmDeriveKey0(passphrase, salt, cost, key_size);
  const cleartext = await window.crypto.subtle.decrypt(
    {
      name: 'AES-GCM',
      iv: iv,
    },
    key,
    ciphertext,
  );
  return cleartext;
}
