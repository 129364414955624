export function bufferToBase64(buffer) {
  const cs = Array.prototype.map.call(buffer,
    c => String.fromCodePoint(c));
  return btoa(cs.join(''));
}

export function base64ToBuffer(base64) {
  const cs = atob(base64);
  const buffer = new Uint8Array(cs.length);
  Array.prototype.forEach.call(cs, (c, i) => {
    buffer[i] = c.charCodeAt(0);
  });
  return buffer;
}
