import React from 'react';
import { kmEncrypt, kmDecrypt } from './Crypto';
import './App.css';

function makeEmptyPasswords() {
  const passwords = {
    schema: 1,
    revision: 0,
    entries: [],
  };

  return passwords;
}

// New password entries should have .entry_id = getNextEntryId(passwords).
function passwordSave(passwords, newPassword) {
  const result = {
    ...passwords,
    revision: (passwords.revision || 0) + 1,
    entries: [...passwords.entries],
  };
  let added = false;
  for (let i = 0; i < result.entries.length; ++i) {
    const p = result.entries[i];
    if (p.entry_id === newPassword.entry_id) {
      result.entries[i] = newPassword;
      added = true;
      break;
    }
  }
  if (!added) {
    result.entries.push(newPassword);
  }
  return result;
}

function getNextEntryID(passwords) {
  let min = -1;
  for (const e of passwords.entries) {
    if (e.entry_id > min)
      min = e.entry_id;
  }
  if (min < 0)
    min = 0;
  min += 1;
  return min;
}

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      file: props.initialFile,
      passphrase: null,
      passwords: null,
      loading: null,
      reset_passphrase: null,
      confirm_clear: null,
    };

    this.onTryDecrypt = this.onTryDecrypt.bind(this);
    this.onSetPassphrase = this.onSetPassphrase.bind(this);
    this.onPasswordsChanged = this.onPasswordsChanged.bind(this);
    this.onExport = this.onExport.bind(this);
    this.onImport = this.onImport.bind(this);
    this.onDoImport = this.onDoImport.bind(this);
    this.onClearReally = this.onClearReally.bind(this);
    this.onClear = this.onClear.bind(this);
    this.onSetNewPassphrase = this.onSetNewPassphrase.bind(this);
  }

  async onTryDecrypt(passphrase) {
    this.setState({
      loading: 'Decrypting...',
    });
    try {
      const { file } = this.state;
      const pass = new TextEncoder('utf-8').encode(passphrase);
      // TODO: Show an error or something on failure.
      // TODO: Add a button to delete current file and start over.
      // TODO: Add a button to export the existing file.
      const buffer = await kmDecrypt(pass, file);
      const decrypted = new TextDecoder('utf-8').decode(buffer);
      const passwords = JSON.parse(decrypted);
      this.setState({
        passphrase: passphrase,
        passwords: passwords,
      });
    } finally {
      setTimeout(() => {
        this.setState({
          loading: null,
        });
      }, 0);
    }
  }

  onSetPassphrase(passphrase) {
    const { passwords } = this.state;
    if (passwords) {
      this.setState({
        loading: 'Encrypting...',
      });
      setTimeout(async () => {
        const pass = new TextEncoder('utf-8').encode(passphrase);
        const data = new TextEncoder('utf-8').encode(JSON.stringify(passwords));
        const file = await kmEncrypt(pass, data);
        localStorage.setItem('file.default', file);
        console.log('saved');
        this.setState({
          passphrase,
          file,
          loading: null,
          // Probably should unset this in all cases, just in case.
          reset_passphrase: null,
        });
      }, 0);
    } else {
      this.setState({
        passphrase,
        passwords: makeEmptyPasswords(),
      });
    }
  }

  async onPasswordsChanged(passwords) {
    this.setState({
      loading: 'Encrypting...',
    });
    try {
      console.log('onPasswordsChanged');
      const { passphrase } = this.state;
      const pass = new TextEncoder('utf-8').encode(passphrase);
      const data = new TextEncoder('utf-8').encode(JSON.stringify(passwords));
      const file = await kmEncrypt(pass, data);
      localStorage.setItem('file.default', file);
      console.log('saved');
    } finally {
      setTimeout(() => {
        this.setState({
          loading: null,
        });
      }, 0);
    }
  }

  onExport() {
    const { file } = this.state;
    const content = new Blob([file], { type: 'octet/stream' });
    const url = window.URL.createObjectURL(content);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'keymaster.dat';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    // Should revoke, but safari on ios encounters an error if you do.
    //window.URL.revokeObjectURL(url);
  }

  onImport() {
    this.setState({
      doing_import: true,
    });
  }

  onDoImport(e) {
    e.preventDefault();
    e.stopPropagation();

    const f = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const contents = e.target.result;
      localStorage.setItem('file.default', contents);
      this.setState({
        passphrase: null,
        passwords: null,
        file: contents,
        doing_import: null,
      });
      e.target.value = '';
    };
    reader.readAsText(f);

    return false;
  }

  onClearReally() {
    this.setState({
      file: null,
      password: null,
      passphrase: null,
      reset_passphrase: null,
      confirm_clear: null,
    });
  }

  onClear() {
    this.setState({
      confirm_clear: true,
    });
  }

  onSetNewPassphrase() {
    this.setState({
      reset_passphrase: true,
    });
  }

  render() {
    const { file, passwords, passphrase, doing_import, loading,
      reset_passphrase, confirm_clear } = this.state;

    let loadingComponent = null;

    if (loading) {
      loadingComponent = (
        <ContextMenu>
          { loading }
        </ContextMenu>
      );
    }

    if (doing_import) {
      return (
        <Page>
          <input type="file" onChange={ this.onDoImport }/>
          { loadingComponent }
        </Page>
      );
    } else if (confirm_clear) {
      return (
        <Page>
          <ContextMenu
            onContextMenuClickOff={
              () => {
                this.setState({ confirm_clear: null });
              }
            }>
            <ContextMenuAction
              onClick={ this.onClearReally }
            >Forget Database</ContextMenuAction>
            <ContextMenuAction
              onClick={
                () => {
                  this.setState({ confirm_clear: null });
                }
              }
            >Cancel</ContextMenuAction>
          </ContextMenu>
          { loadingComponent }
        </Page>
      );
    } else if (reset_passphrase) {
      return (
        <Page>
          <PassphraseInput prompt="Set new passphrase for existing password file." onClear={ this.onClear } onImport={ this.onImport } onSubmit={ this.onSetPassphrase }/>
          { loadingComponent }
        </Page>
      );
    } else if (passwords && passphrase) {
      return (
        <Page>
          <PasswordList
            passwords={ passwords }
            onPasswordsChanged={ this.onPasswordsChanged }
            onExport={ this.onExport }
            onImport={ this.onImport }
            onSetNewPassphrase={ this.onSetNewPassphrase }
          />
          { loadingComponent }
        </Page>
      );
    } else if (file) {
      return (
        <Page>
          <PassphraseInput prompt="Enter passphrase for existing password file." onClear={ this.onClear } onImport={ this.onImport } onSubmit={ this.onTryDecrypt }/>
          { loadingComponent }
        </Page>
      );
    } else {
      return (
        <Page>
          <PassphraseInput prompt="Set a passphrase for a new password file." onClear={ this.onClear } onImport={ this.onImport } onSubmit={ this.onSetPassphrase }/>
          { loadingComponent }
        </Page>
      );
    }
  }
}

class PassphraseInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      passphrase1: '',
      passphrase2: '',
    };

    this.onFieldChanged = this.onFieldChanged.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onImport = this.onImport.bind(this);
    this.onClear = this.onClear.bind(this);
  }

  onFieldChanged(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    const { passphrase1 } = this.state;
    if (this.props.onSubmit) {
      this.props.onSubmit(passphrase1);
    }

    return false;
  }

  onImport(e) {
    e.preventDefault();
    e.stopPropagation();

    setTimeout(() => {
      if (this.props.onImport) {
        this.props.onImport();
      }
    }, 0);

    return false;
  }

  onClear(e) {
    e.preventDefault();
    e.stopPropagation();

    setTimeout(() => {
      if (this.props.onClear) {
        this.props.onClear();
      }
    }, 0);

    return false;
  }

  render() {
    const { passphrase1 } = this.state;
    return (
      <div className="centering">
        <form action="#" onSubmit={ this.onSubmit }>
          <div className="passphrase-input">
            { this.props.prompt }
            <input type="password" name="passphrase1" value={ passphrase1 } onChange={ this.onFieldChanged }/>
            <button type="submit" onClick={ this.onSubmit }>Continue</button>
            <button onClick={ this.onImport }>Import</button>
            <button onClick={ this.onClear }>Forget Database</button>
          </div>
        </form>
      </div>
    );
  }
}

class PasswordList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      passwords: props.passwords,
      selected_password: null,
      quick_password: null,
      show_password_password: null,
      edited_password: null,
      show_sausage_menu: null,
    };
  }

  render() {
    const {
      passwords, selected_password, quick_password, show_password_password,
      edited_password, show_sausage_menu,
    } = this.state;

    const showMain = !selected_password;
    const showPassword = !!selected_password;

    if (showMain) {
      const password_items = [];
      const entries = passwords.entries.slice();
      entries.sort((a, b) => {
        if (a.category < b.category)
          return -1;
        else if (a.category > b.category)
          return 1;
        return a.title.localeCompare(b.title);
      });
      let lastCategory = null;
      let categoryID = -1;
      for (const password of entries) {
        if (lastCategory == null || lastCategory !== password.category) {
          password_items.push((
            <PasswordCategory key={ categoryID-- }>{ password.category }</PasswordCategory>
          ));
          lastCategory = password.category;
        }
        password_items.push((
          <PasswordLine key={ password.entry_id } password={ password } onClick={
            () => {
              this.setState({ quick_password: password });
            }
          }/>
        ));
      }

      let contextMenu = null;

      if (show_password_password) {
        contextMenu = (
          <ContextMenu name="show_password"
            onContextMenuClickOff={
              () => {
                this.setState({ show_password_password: null });
              }
            }
          >
            <ContextMenuAction>
              { show_password_password == null ? '' : show_password_password.password }
            </ContextMenuAction>
          </ContextMenu>
        );
      } else if (quick_password != null) {
        contextMenu = (
          <ContextMenu
            onContextMenuClickOff={
              () => {
                this.setState({ quick_password: null });
              }
            }>
            <ContextMenuAction
              onClick={
                () => {
                  this.setState({ quick_password: null, show_password_password: quick_password });
                }
              }
            >
              Show Password
            </ContextMenuAction>
            <ContextMenuAction onClick={
              () => {
                this.setState({ quick_password: null, selected_password: quick_password });
              }
            }>
              Edit
            </ContextMenuAction>
          </ContextMenu>
        );
      } else if (show_sausage_menu) {
        contextMenu = (
          <ContextMenu
            onContextMenuClickOff={
              () => {
                this.setState({ show_sausage_menu: null });
              }
            }>
            <ContextMenuAction
              onClick={
                () => {
                  this.setState({ show_sausage_menu: null });
                  setTimeout(() => {
                    if (this.props.onExport) {
                      this.props.onExport();
                    }
                  }, 0);
                }
              }
            >Export</ContextMenuAction>
            <ContextMenuAction
              onClick={
                () => {
                  this.setState({ show_sausage_menu: null });
                  setTimeout(() => {
                    if (this.props.onImport) {
                      this.props.onImport();
                    }
                  }, 0);
                }
              }
            >Import</ContextMenuAction>
            <ContextMenuAction
              onClick={
                () => {
                  this.setState({ show_sausage_menu: null });
                  setTimeout(() => {
                    if (this.props.onSetNewPassphrase) {
                      this.props.onSetNewPassphrase();
                    }
                  }, 0);
                }
              }
            >Set New Passphrase</ContextMenuAction>
          </ContextMenu>
        );
      }

      return (
        <Page name="main">
          <PageNav
            left={
              <button onClick={
                () => {
                  this.setState({
                    show_sausage_menu: true,
                  });
                }
              }>...</button>
            }
            right={
              <button onClick={
                () => {
                  this.setState({
                    selected_password: { entry_id: getNextEntryID(passwords) },
                  });
                }
              }>New</button>
            }
          />
          <PageContents>
            { password_items }
          </PageContents>
          { contextMenu }
        </Page>
      );
    } else if (showPassword) {
      return (
        <Page name="password_editor">
          <PageNav
            left={
              <button onClick={
                () => {
                  this.setState({ selected_password: null, edited_password: null, });
                }
              }>Cancel</button>
            }
            right={
              <button onClick={
                () => {
                  const newPassword = edited_password || selected_password;
                  const nextPasswords = passwordSave(passwords, newPassword);
                  this.setState({
                    passwords: nextPasswords,
                    selected_password: null,
                    edited_password: null,
                  });
                  // TODO: Is this okay? Can I send stale state around if I do this?
                  setTimeout(() => {
                    console.log(this.props.onPasswordsChanged);
                    if (this.props.onPasswordsChanged) {
                      this.props.onPasswordsChanged(nextPasswords);
                    }
                  }, 0);
                }
              }>Save</button>
            }
          />
          <PageContents>
            <PasswordFields key={ selected_password ? selected_password.entry_id : -1 } password={ selected_password }
              onChange={
                (p) => {
                  this.setState({ edited_password: p });
                }
              }
            />
          </PageContents>
        </Page>
      );
    }

    return (<div>ERROR</div>);
  }
}

class PasswordFields extends React.Component {
  constructor(props) {
    super(props);

    const p = props.password || {};

    this.state = {
      entry_id: p.entry_id || null,
      title: p.title || '',
      category: p.category || '',
      username: p.username || '',
      password: p.password || '',
      notes: p.notes || '',
    };
  }

  onFieldChanged(e) {
    const diff = {
      [e.target.name]: e.target.value,
    };
    const next = {
      ...this.state,
      ...diff,
    };
    this.setState(diff);
    if (this.props.onChange) {
      this.props.onChange(next);
    }
  }

  render() {
    const onFieldChanged = this.onFieldChanged.bind(this);
    const { title, category, username, password, notes } = this.state;

    return (
      <div className="password-fields">
        <label htmlFor="title">Title</label>
        <br/>
        <input type="text" name="title" value={ title } autoCapitalize="words"
          onChange={ onFieldChanged }/>
        <br />
        <label>Category</label>
        <br/>
        <input type="text" name="category" value={ category } autoCapitalize="words"
          onChange={ onFieldChanged }/>
        <br />
        <label>Username</label>
        <br/>
        <input type="text" name="username" autoComplete="off" value={ username } autoCapitalize="off"
          onChange={ onFieldChanged }/>
        <br />
        <label>Password</label>
        <br/>
        <input type="text" name="password" autoComplete="off" value={ password } autoCapitalize="off"
          onChange={ onFieldChanged }/>
        <br />
        <label>Notes</label>
        <br/>
        <textarea name="notes" value={ notes }
          onChange={ onFieldChanged }/>
      </div>
    );
  }
}

function Page(props) {
  return (
    <div className="page">
      { props.children }
    </div>
  );
}

function PageContents(props) {
  return (
    <div className="contents">
      { props.children }
    </div>
  );
}

function PageNav(props) {
  return (
    <nav>
      <div>{ props.left }</div>
      <div className="center">{ props.center }</div>
      <div>{ props.right }</div>
    </nav>
  );
}

function ContextMenu(props) {
  return (
    <div className="overlay" onClick={
      () => {
        if (props.onContextMenuClickOff)
          props.onContextMenuClickOff();
      }
    }>
      <div className="popup-box" onClick={
        // Prevent clicks on the popup box and its content from propagating to the
        // overlay.
        (e) => {
          e.preventDefault();
          e.stopPropagation();
          return false;
        }
      }>
        { props.children }
      </div>
    </div>
  );
}

function ContextMenuAction(props) {
  // TODO: Add property to control whether the item is clickable or not.
  // unclickable makes the text fade and no cursor: pointer.
  return (
    <div className="popup-box-item" onClick={ props.onClick }>
      { props.children }
    </div>
  );
}

function PasswordCategory(props) {
  return (
    <div className="password-list-category">
      <div>{ props.children }</div>
    </div>
  );
}

function PasswordLine(props) {
  const { password } = props;
  return (
    <div className="password-list-entry"
      onClick={ props.onClick }>
      <div className="entry-title">{ password.title }</div>
      <div className="entry-subtitle">{ password.username }</div>
    </div>
  );
}

export default App;
