import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

const initialFile = localStorage.getItem('file.default');

ReactDOM.render(
  <React.StrictMode>
    <App initialFile={ initialFile }/>
  </React.StrictMode>,
  document.getElementById('root'),
);
